import { Helmet } from "react-helmet";

export const ProjectTitle = ({ title, pageName }) => {
  return (
    <>
      <Helmet>
        <title>
          {title ?? "title"} | {pageName} - Sappay.net{" "}
        </title>
      </Helmet>
    </>
  );
};
