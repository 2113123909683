import React from "react";

const Input = ({
  label,
  // error,
  value,
  placeholder,
  id,
  name,
  type,
  ...props
}) => {
  return (
    <div className=" max-w-3xl  ">
      <label className="block" htmlFor={id}>
        {label.toUpperCase()}
      </label>
      <input
        type={type}
        id={id}
        name={name}
        className=" border w-full  focus:outline-none  text-sm    block  p-4  hover:border-black"
        placeholder={placeholder}
        value={value}
        {...props}
      />
    </div>
  );
};

export default Input;
