import React from "react";
import Select from "react-select";

const CountrySelector = ({
  value,
  option,
  label,
  placeholder,
  id,
  name,
  ...props
}) => {
  return (
    <>
      <div className="max-w-2xl ">
        <label htmlFor={id} className="block">
          {label.toUpperCase()}
        </label>

        <Select
          styles={{
            control: (provided, state) => ({
              ...provided,
              padding: "0.5rem",
              borderColor: "#E5E7EB",
              boxShadow: "#E5E7EB",
              borderRadius: "0.75rem",
              fontWeight: "600",
              fontSize: "16px",

              "&:hover": {
                borderColor: "black",
                boxShadow: "#E5E7EB",
              },
            }),
            option: (provided) => ({
              ...provided,
              backgroundColor: "white",
              fontWeight: "600",
              fontSize: "16px",
              color: "black",
              ":active": {
                backgroundColor: "white",
                color: "black",

                borderColor: "black",
              },
              "&:hover": {
                backgroundColor: "#A9A9A9",
              },
            }),
            placeholder: (provided) => ({
              ...provided,
              color: "#A3A3A3",
              fontfamily: "Poppins",

              fontSize: "16px",
              fontWeight: "600",
            }),
          }}
          options={option}
          id={id}
          name={name}
          placeholder={placeholder}
          value={value}
          {...props}
        />
      </div>
    </>
  );
};

export default CountrySelector;
