import React from "react";
import { SVG } from "../../common/icon";
const Button = ({ type,name }) => {
  return (
    <>
      <div className="className=px-[2.5rem] py-[1.25rem]  bg-[#FDD7D7] border-[0.063] border-solid border-[#FDD7D7] rounded-[353.125rem] max-w-3xl ">
        <button
          type="submit"
          className="hoverArrow flex items-center justify-center w-full "
        >
          <p className="text-black font-semibold">{name}</p>{" "}
          <span>{SVG.arrowForward}</span>
        </button>
      </div>
    </>
  );
};

export default Button;
