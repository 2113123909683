import { useEffect, useState } from "react";
import { getEvent, donateEvent } from "../../common/api";
import { Link } from "react-router-dom";
import Input from "../formItems/input";
import CountrySelector from "../formItems/countrySelector";

import Button from "../formItems/button";
import BackButton from "../formItems/backButton";
import AmountField from "../formItems/amountField";
import { useFormik } from "formik";
import { fundRaisingSchema } from "./validation-schema";
import PhoneNumber from "../formItems/phonefield";
import { getCountries } from "../../common/api";
import { useParams } from "react-router-dom";
import { ProjectTitle } from "../../common/project-title";
import { startCase } from "lodash";

const initialValues = {
  firstName: "",
  lastName: "",
  country: "",
  phone: "",
  email: "",
  amount: "",
  event: "",
};

const FundraisingForm = () => {
  const [event, setEvent] = useState();
  const [deadline, setDeadline] = useState();
  const [error, setError] = useState(null);
  // const navigate = useNavigate();
  const { id } = useParams();
  // const [isFilePicked, setIsFilePicked] = useState({
  //   frontId: false,
  //   backId: false,
  // });

  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState("");

  const fetchCountries = async () => {
    try {
      const { response } = await getCountries();

      const transformResponse = response.map((countryObect) => {
        console.log(countryObect);
        return {
          label: countryObect.name,
          value: countryObect.id,
          phone: countryObect.phone,
        };
      });

      setCountries(transformResponse);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const onSubmit = (values, action) => {
    values.event = event.id;
    donateEvent(values).then(({ response }) => {
      const url = response.response.checkout_url;
      window.location.href = url;
      // navigate(url, { replace: true });
    });

    action.resetForm();
  };
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: fundRaisingSchema,
    onSubmit,
  });

  useEffect(() => {
    getEvent(id)
      .then(({ response }) => {
        setEvent(response);

        if (response.deadline) {
          const date = new Date();
          const deadline = new Date(response.deadline);
          const timeDiff = Math.abs(date - deadline);
          const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
          setDeadline(daysLeft);
        }
      })
      .catch((error) => {
        setError(error); // Set a custom error message
      });
    fetchCountries();
  }, [id]);

  return (
    <>
      <ProjectTitle title={event?.title} pageName={"Fund Raising Form"} />
      {event ? (
        <>
          <div
            style={{
              backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), url(${event?.cover})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              height: "100vh",
            }}
          >
            <div className="container  xs:px-[1rem] xs:mx-auto">
              <div className="title  max-w-3xl">
                <div className="  max-w-3xl mb-[1.5rem]">
                  <img
                    src={event?.logo ?? "/pngwing.png"}
                    width="48px"
                    height="48px"
                    alt=""
                    style={{ display: "inline", marginTop: "-1rem" }}
                  />
                  <h2 style={{ display: "inline" }}>
                    {startCase(event?.business_name)}
                  </h2>
                </div>

                <div className="flex  flex-wrap xs:gap-[1rem] sm:gap-[3rem]">
                  <div>
                    <h2>{`${event?.amount_required} XOF` ?? "200,000 XOF"}</h2>
                    <label>AMOUNT NEEDED </label>
                  </div>

                  <div>
                    <h2>{event?.collected_amount ?? "0 XOF"}</h2>
                    <label>AMOUNT RAISED </label>
                  </div>
                  <div>
                    <h2>{`${deadline ?? 30} Days `} </h2>
                    <label>TIME LEFT </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container  xs:px-[1rem] xs:mx-auto     pb-[2rem]">
            <div className=" xs:mt-[2rem] max-w-3xl">
              <div className="">
                <h1>Donate.</h1>
                <form onSubmit={handleSubmit}>
                  <div className="grid  md:grid-cols-2  gap-x-[2rem] gap-y-[1.5rem] mt-[2rem]">
                    <div>
                      <Input
                        label="First Name"
                        placeholder="e.g John"
                        name="firstName"
                        id="firstName"
                        type="text"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.firstName && touched.firstName ? (
                        <p className="text-red-400">{errors.firstName}</p>
                      ) : null}
                    </div>
                    <div>
                      <Input
                        label="Last Name"
                        placeholder="e.g Elder"
                        name="lastName"
                        id="lastName"
                        type="text"
                        onChange={handleChange}
                        value={values.lastName}
                        onBlur={handleBlur}
                      />
                      {errors.lastName && touched.lastName ? (
                        <p className="text-red-400">{errors.lastName}</p>
                      ) : null}
                    </div>

                    <div>
                      <CountrySelector
                        name="country"
                        option={countries}
                        placeholder="Select a Country"
                        id="country"
                        label="Country"
                        onChange={(countryObject) => {
                          setFieldValue("country", countryObject.value);
                          setCountryCode(countryObject.phone);
                        }}
                        value={values.country && values.country.value}
                        onBlur={handleBlur}
                      />
                      {errors.country && touched.country ? (
                        <p className="text-red-400">{errors.country}</p>
                      ) : null}
                    </div>
                    <div>
                      <PhoneNumber
                        name="phone"
                        placeholder="xxx xxxxxxx"
                        id="phone"
                        label="Phone No"
                        type="phone"
                        countryCode={countryCode}
                        value={values.phone}
                        onChange={(value) => setFieldValue("phone", value)}
                        onBlur={handleBlur}
                      />
                      {errors.phone && touched.phone ? (
                        <p className="text-red-400">{errors.phone}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className=" mb-[1.5rem] mt-[1.5rem]">
                    <Input
                      placeholder="e.g john@xyz.com"
                      label="Email"
                      type="email"
                      name="email"
                      id="email"
                      onChange={handleChange}
                      value={values.email}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email ? (
                      <p className="text-red-400">{errors.email}</p>
                    ) : null}
                  </div>

                  <div className=" mb-[1.5rem] mt-[1.5rem] max-w-3xl">
                    <AmountField
                      placeholder="enter donation amount"
                      label="AMOUNT"
                      id="amount"
                      name="amount"
                      type="text"
                      value={values.amount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.amount && touched.amount ? (
                      <p className="text-red-400">{errors.amount}</p>
                    ) : null}
                  </div>

                  <div className=" flex  max-w-3xl gap-x-[1.5rem]  ">
                    <Link to={`/${id}`}>
                      <div className="">
                        <BackButton />
                      </div>
                    </Link>
                    <div style={{ width: "100%" }}>
                      <Button name={"Donate"} />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        `${error}`
      )}
    </>
  );
};

export default FundraisingForm;
