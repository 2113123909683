import React from "react";
import { SVG } from "../../common/icon";
const BackButton = () => {
  return (
    <>
      <button className="border-[0.063rem]  border-solid border-[#A3A3A3] rounded-[353.125rem] p-[1.25rem]">
        {SVG.arrowBackward}
      </button>
    </>
  );
};

export default BackButton;
