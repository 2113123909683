import React from "react";

const AmountField = ({
  label,
  error,
  value,
  placeholder,
  id,
  name,
  type,
  ...props
}) => {
  return (
    // <div className={error ? "error" : ""}>
    // {/* <div className="grid gap-2 m-6 max-w-sm"> */}
    <div className=" max-w-3xl  relative  ">
      <label className="block" htmlFor={id}>
        {label.toUpperCase()}
      </label>

      <input
        inputMode="numeric"
        pattern="[0-9]*"
        autoComplete="off"
        type={type}
        id={id}
        name={name}
        className=" border w-full  focus:outline-none  text-sm    block  p-4 pl-[1.25rem] hover:border-black  "
        placeholder={placeholder}
        value={value}
        // style={{
        //   backgroundImage: "url(/Union.svg)",
        //   backgroundPosition: "right 1.313rem center",
        //   backgroundRepeat: "no-repeat",

        // }}
        {...props}
      />
      <p className="absolute right-2 bottom-3 font-semibold">CFA</p>
    </div>
  );
};

export default AmountField;
