import * as yup from "yup";

export const fundRaisingSchema = yup.object({
  firstName: yup.string().min(2).max(25).required("Please enter your name"),
  lastName: yup.string().min(2).max(25).required("Please enter your last name"),
  country: yup.string().required("Please select country "),

  phone: yup.string().trim().required("Please enter phone number"),
  email: yup.string().email().required("Please enter your email"),
  amount: yup
    .string()
    .matches(/^[0-9]*$/, "Amount should be only in numbers")
    .required("Please enter amount"),
});
