import api from "./api-client";

export const getEvent = async (id) => {
  const response = await api.get(`/view_event/?public_id=${id}`);
  return response.data;
};

export const donateEvent = async (donar) => {
  const response = await api.post("/donate/", donar);
  return response.data;
};

export const getAllCountries = () => {
  return api.get(`/countries`);
};

export const getCountries = async (searchString, countryId) => {
  const response = await api.get(
    `/countries/?search=${
      typeof searchString === "string" ? searchString : ""
    }&country_id=${typeof countryId === "string" ? countryId : ""}`
  );
  return response.data;
};
