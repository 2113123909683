import React, { useEffect, useState } from "react";
import { getEvent } from "../../common/api";
import { SVG } from "../../common/icon";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ProjectTitle } from "../../common/project-title";
import { startCase } from "lodash";

const Fundraising = () => {
  //hooks
  const [event, setEvent] = useState();
  const [deadline, setDeadline] = useState();
  const [error, setError] = useState(null);
  const [copyIcon, setCopyIcon] = useState(SVG.urlCopy);
  const { id } = useParams();
  const url = window.location.href;

  useEffect(() => {
    getEvent(id)
      .then(({ response }) => {
        setEvent(response);

        if (response.deadline) {
          const date = new Date();
          const deadline = new Date(response.deadline);
          const timeDiff = Math.abs(date - deadline);
          const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
          setDeadline(daysLeft);
        }
      })
      .catch((error) => {
        setError(error); // Set a custom error message
      });
  }, [id]);

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    setCopyIcon(SVG.tick);
    setTimeout(() => {
      setCopyIcon(SVG.urlCopy);
    }, 1000);
  };

  return (
    <>
      <ProjectTitle
        title={startCase(event?.title)}
        pageName={"Fund Raising dashboard"}
      />
      {event ? (
        <>
          <div
            style={{
              backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 70.6%), url(${
                event?.cover ??
                "https://cdn.siasat.com/wp-content/uploads/2023/02/Turkey-earthquake-1-1.jpg"
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              height: "100vh",
            }}
          >
            <div className="container xs:px-[1rem] mx-auto ">
              <div className="fundraisingTitle">
                <div className="  max-w-3xl  mb-[1.5rem]">
                  <img
                    src={event?.logo ?? "/pngwing.png"}
                    width="48px"
                    height="48px"
                    alt=""
                    style={{
                      display: "inline",
                      marginTop: "-1rem",
                    }}
                  />
                  <h2 style={{ display: "inline" }}>
                    {startCase(event?.business_name)}
                  </h2>
                </div>

                <h1 className="max-w-3xl xs:mb-[0.5rem] ">
                  {startCase(event?.title ?? "N/A")}
                </h1>

                <div className="flex flex-wrap xs:gap-[1rem] sm:gap-[3rem]">
                  <div>
                    <h2>{event?.amount_required ?? "200,000 XOF"}</h2>
                    <label>AMOUNT NEEDED </label>
                  </div>

                  <div>
                    <h2>{event?.collected_amount ?? "0 XOF"}</h2>
                    <label>AMOUNT RAISED </label>
                  </div>
                  <div>
                    <h2>{`${deadline ?? 30} Days `} </h2>
                    <label>TIME LEFT </label>
                  </div>
                </div>

                <div className="flex gap-[1.5rem] mt-[2rem]">
                  <Link to={`/fundraising-form/${id}`}>
                    <button className="hoverArrow bg-pink-200 hover:bg-pink-300 text-black  py-2 px-6 rounded-full h-[3rem]  flex items-center hover>span:bg-black-200 ">
                      Donate Now <span>{SVG.arrowForward}</span>
                    </button>
                  </Link>

                  <button
                    className=" text-black  border-[0.063rem]  border-solid  border-[#A3A3A3] h-[3rem] w-[3rem] rounded-[1.5rem] hover:border-[#24C45F]"
                    type="button"
                    // style={{ position: "absolute", right: "0.5rem" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCopy();
                    }}
                  >
                    <span className=" text-center">{copyIcon}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className=" container xs:px-[2rem] mx-auto  ">
            <div className="flex  justify-between  gap-6 xs:flex-wrap sm:flex-nowrap ">
              <div className="xs:w-[100%] sm:w-[100%] md:w-[55%]    ">
                <h3 className="">Description:</h3>
                <p style={{ overflowWrap: "anywhere" }}>
                  {event?.description.charAt(0).toUpperCase() +
                    event?.description.slice(1) ?? "N/A"}
                </p>
              </div>

              <div className="  body xs:w-[100%] sm:w-[100%] md:w-[40%]  ">
                <h3>Recent Donations</h3>
                <div className="flex justify-between border-b-[1px] border-[#EAEAEA]">
                  <h5
                    style={{ overflowWrap: "anywhere" }}
                    className="  border-b-[1px] border-[#EAEAEA] "
                  >
                    $5,000
                  </h5>
                  <label>Saudi Arabia</label>
                </div>

                <div className="flex justify-between border-b-[1px] border-[#EAEAEA]">
                  <h5
                    style={{ overflowWrap: "anywhere" }}
                    className="  border-b-[1px] border-[#EAEAEA] "
                  >
                    $5,000
                  </h5>
                  <label>United Kingdom</label>
                </div>

                <div className="flex justify-between border-b-[1px] border-[#EAEAEA]">
                  <h5
                    style={{ overflowWrap: "anywhere" }}
                    className="  border-b-[1px] border-[#EAEAEA] "
                  >
                    $5,000
                  </h5>
                  <label>United States</label>
                </div>

                <div className="flex justify-between border-b-[1px] border-[#EAEAEA]">
                  <h5
                    style={{ overflowWrap: "anywhere" }}
                    className="  border-b-[1px] border-[#EAEAEA] "
                  >
                    $5,000
                  </h5>
                  <label>UAE</label>
                </div>
              </div>
            </div>
          </div>
          <small className=" block text-center pt-[4rem]">
            Made with SAPPAY
          </small>
        </>
      ) : (
        `${error}`
      )}
    </>
  );
};

export default Fundraising;
