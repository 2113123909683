import { useEffect } from "react";

const PhoneNumber = ({
  value,
  name,
  id,
  label,
  type,
  placeholder,
  countryCode,
  onChange,
  ...props
}) => {
  useEffect(() => {
    if (countryCode && !value?.startsWith(`+${countryCode} `)) {
      onChange(value.replaceAll(`+${countryCode}`, `+${countryCode} `));
    }
    // eslint-disable-next-line
  }, [value, countryCode]);
  return (
    <>
      <div className="max-w-3xl">
        <label className="block" htmlFor={id}>
          {label.toUpperCase()}
        </label>
        <input
          className=" border w-full  focus:outline-none  text-sm    block  p-4 hover:border-black"
          type={type}
          id={id}
          name={name}
          placeholder={placeholder}
          value={
            value?.startsWith(`+${countryCode} `)
              ? value
              : countryCode
              ? `+${countryCode} `
              : ""
          }
          onChange={(e) => {
            if (e.target.value.length >= `+${countryCode} `.length) {
              onChange(
                `+${countryCode} ${e.target.value.replaceAll(
                  `+${countryCode} `,
                  ""
                )}`
              );
            }
          }}
          {...props}
        />
      </div>
    </>
  );
};
export default PhoneNumber;
